import { useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import '../../App.scss';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FaAngleLeft } from "react-icons/fa";


// Msal imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

function EmailSignature() {
  

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [copyButton, setCopyButton] = useState(true);

  const handleNameChange = (role, e) => {
    switch (role) {
      case 'name':
        setName(e.target.value);
        break;
      case 'role':
        setRole(e.target.value);
        break;
      case 'number':
        setPhoneNumber(e.target.value);
        break;
      default:
    }
  }

  function CopyToClipboard() {
    //document.querySelector(".show-copied").style.display = "flex";
    setCopyButton(false);

    var range = document.createRange();

    range.selectNodeContents(document.getElementById("signature-card"));

    setTimeout(function () {
      setCopyButton(true);
    }, 1000);

    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
  }

  const copyButtonOnOff = () => {
    if (copyButton === true) {
      return (
        <div className="copy btn-copy" onClick={() => CopyToClipboard()}>Copy to Clipboard</div>
      )
    } else {
      return (
        <div className="copy copied">Signature Copied!</div>
      )
    }
  }

  return (
    <>
      <AuthenticatedTemplate>

        <div className="App">

          <div className="header">
            <div className="header-container">
              <h1>innovio</h1>
              <h2>Email Signature Generator</h2>
            </div>
          </div>

          <div className="app-inner">
            <Link to="/" className="back-home">
              <FaAngleLeft className='fas' /> Back
            </Link>
            {/* FORM EDITOR */}
            <div className="inner-header">
              <div className="instructions">
                <b>Instructions:</b><br />
                Fill out your details,<br />
                Click the "Copy to Clipboard" button,<br />
                Go to the signature section of your email client and paste in your new signature!<br />
                Need help setting up your Email Signature in Outlook? ... <a className="aLink" href="https://support.microsoft.com/en-us/office/create-and-add-an-email-signature-in-outlook-com-776d9006-abdf-444e-b5b7-a61821dff034" target="_blank" rel="noreferrer">Click here</a>
              </div>
              <div className="form-fields-container">
                <input type="text" placeholder="Full Name" value={name} onChange={(e) => handleNameChange('name', e)} />
                <input type="text" placeholder="Company Role" value={role} onChange={(e) => handleNameChange('role', e)} />
                <input type="text" placeholder="Contact Number" value={phoneNumber} onChange={(e) => handleNameChange('number', e)} />
              </div>
            </div>

            {/* FOOTER SIGNATURE */}
            <div className="signature-card">
            <table cellPadding="0" cellSpacing="0" id="signature-card" style={{ verticalAlign: "-webkit-baseline-middle", fontSize: "medium", fontFamily: "Arial", background: 'white', borderRadius: '4px', padding: '16px', border: '1px solid rgba(197,197,197, .27)' }}>
                <tbody>
                  <tr>
                    <td>
                      <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: 'medium', fontFamily: 'Arial' }}>
                        <tbody>
                          <tr>
                            <td width="80" style={{ verticalAlign: 'middle', paddingBottom: '8px' }}>
                              <span style={{ marginRight: '12px', display: 'block' }}>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO2SURBVHgB7ZtPSBRRHMe/W0IZ/kEhyDIodMFdO2iE6yH1kmIIHXQjgyDNU4fUQ1HSscTCLotEBEkdgjRS6GDGJhTZQdPQLipqEbT+IaFgd82EouY3siKhzvu9mdnGZj4wuKxvx3mf+b3f7zdPdR3ILv4NG7MNNscRAJvjCIDNcQTA5jgCYHMcAbA5theQgH+M15ONQl8efAX56uuUlCT1IMLhqHqEZuYxNj6NwcERDLwdVd8zCpfI0+D7dz2rFyXCxcst6Op+vumYqspy+CuPq5Pn8kQ5d6DtPkKheehFaAmQfQ50JzeCJtz/qhO3bjZJTZ7wK/L6X3aiVTlHZuYe6EFQwBQ4eD3udd9vvFCLRw8DyNyn76JjkAg632bCtYhbBLTeaEJDfQ2MhmTqkSAkYHyCJ4DyxdrQpMn7q8phFvTzZCWIRcDYNDvzenNWLobC3szJxyAJd+80s5I1IdwHUCni4FHuBmV6M8J+I2g51J71sz4jLICbCAt9+Wisr0W8OVdzkhUFwgLGmYmQSpxR2Z4DTZ4TBcKdILcSaJ3rRV+/+nXteUkYHaWlRSg7dhSyUBQE2h4IjXWJ/l6AzFJHqAfq3K41tyHY90ZzLImgpCZb3k6facDA4KjmOOEloPbkOlpP+ixdlMjk1fFK0q04Uae2vTJ4csTEsZ4GxybklkFs8txKQlDEyIj3ek0QEA5HIIM6iRm56KHIu3SlBVy8OW6hcabvB9A6FA37zc7BbcRSUsVKoekCurp7YQRBpWpwEC3BpgvQe/djGPHsvx6mCqB1b9TujWwO0cJcASbdNSNxdoVhc6R2hWM9PO3Sxl5Ty0qbIL6CPHVLTHa/L964OH8jRKUlHIkKJzYSYlQeoL0F2kjlcNBdojmGFQHcTOwkwS2AVA44nJWK4kPpypGGjLQdq+9PzX7H5Owi2oOfMfd1GVsBloDkxARcrc5CSW76ut93792lHhVHdqNneAGBp58QWfoJK8NaArfP5244+b8hCTTe6ggLOFWcod5dDjS+rmw/rIy4gKIMyFBdFP+NUQ5CApKUtb822XFY+exOWBUhAcmJ2/G/IiQgsvQLeoj+sG4lEBIQVUrZpFLjZZj7tmzpUiicBHuHv0CG9mAIVkZYQMfrOaW54Umg8T1DcuLiBasTvN7xAfNKi6tV2yPKmn+sCLtn8btPsJ8FaFLU5qrPAkpXSGUuViUmZxYx8jGMZ0MLhq/7SCRqytOly/mfIZvjCIDNcQTA5jgCYHMcAbA5jgDYnD9koVjk+1iwUAAAAABJRU5ErkJggg==" alt="innovio technologies Logo" role="presentation" width="64" height="64" style={{ maxWidth: '64px', maxHeight: '64px' }} />
                              </span>
                            </td>
                            <td style={{ verticalAlign: 'middle', paddingBottom: '8px' }}>
                              <h3 style={{ textAlign: 'left', margin: '0px', fontSize: '16px', color: 'rgb(0, 0, 0)' }}>{name}</h3>
                              <p style={{ textAlign: 'left', margin: '0px', color: 'rgb(0, 0, 0)', fontSize: '12px' }}>{role}</p>
                              {/* <p style={{ textAlign: 'left', margin: '0px', fontWeight: '500', color: 'rgb(0, 0, 0)', fontSize: '14px', paddingTop: '4px' }}>innovio</p> */}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" style={{ borderTop: '1px solid #ddd', paddingTop: '12px' }}>
                              <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: 'medium', fontFamily: 'Arial', paddingLeft: '8px', paddingRight: '8px' }}>
                                <tbody>
                                  {phoneNumber ?
                                    <tr height="18" style={{ verticalAlign: 'top' }}>
                                      <td width="20" style={{ textAlign: 'right', verticalAlign: 'top', fontSize: '12px', color: '#777', paddingRight: '4px' }}>tel:</td>
                                      <td style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777', padding: '0px' }}>
                                        <span color="#777" style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777' }}>
                                          {phoneNumber}
                                        </span>
                                      </td>
                                    </tr>
                                    :
                                    null
                                  }
                                  <tr height="18" style={{ verticalAlign: 'top' }}>
                                    <td width="20" style={{ textAlign: 'right', verticalAlign: 'top', fontSize: '12px', color: '#777', paddingRight: '4px' }}>web:</td>
                                    <td style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777', textDecoration: 'none', padding: '0px' }}>
                                      <a href="https://innovio.io" target="_blank" rel="noreferrer" color="#777" style={{ textAlign: 'left', verticalAlign: 'top', textDecoration: 'none', color: '#777', fontSize: '12px' }}>
                                        <font color='#777' style={{ textAlign: 'left' }}>www.innovio.io</font>
                                      </a>
                                    </td>
                                  </tr>
                                  {/* <tr height="18" style={{ verticalAlign: 'top' }}>
                                    <td width="20" style={{ textAlign: 'right', verticalAlign: 'top', fontSize: '12px', color: '#777', paddingRight: '4px' }}>addr:</td>
                                    <td style={{ verticalAlign: 'top', padding: '0px' }}>
                                      <span color="#777" style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777' }}>
                                        Aragon House Business Centre,<br />Dragonara Road, St Julian's, Malta
                                      </span>
                                    </td>
                                  </tr> */}

                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

            {/* ACTIONS */}
            <div className="actions">
              {copyButtonOnOff()}
            </div>


          </div>

        </div>

      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    </>
  );
}

export default EmailSignature;
