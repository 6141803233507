// import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import EmailSignature from './Pages/EmailSignature/EmailSignature';
import './App.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import {FaFileContract} from 'react-icons/fa';

// Msal imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
// import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from './authConfig'

import { ErrorComponent } from './ui-components/ErrorComponent'
import { Loading } from './ui-components/Loading'




function App({ pca }) {

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Router>
        <div className="pageNav">
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/emailSignature">
              <EmailSignature />
            </Route>
          </Switch>
        </div>
      </Router>
    </MsalProvider>
  );
}

export default App;

function Home() {

  const authRequest = {
    ...loginRequest
  };

  return (
    <>
      <MsalAuthenticationTemplate
        // interactionType={InteractionType.Popup}
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >

        <AuthenticatedTemplate>

          <div className="App">

            <div className="header">
              <div className="header-container">
                <h1>innovio</h1>
                <h2>Internal Tools</h2>
              </div>
            </div>

            <div className="app-inner flex-center">

              <Link to="/emailSignature" className="home-item email-signature">
                <FaFileContract className="fas" />
                Email Signature
              </Link>
            </div>
          </div>
        </AuthenticatedTemplate>
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <center>Please sign-in to see your profile information.</center>
      </UnauthenticatedTemplate>
    </>
  );
}